<template>
  <!-- Begin Mailchimp Signup Form -->

  <div
    id="mc_embed_signup"
    class="bg-white shadow-lg rounded-lg"
  >
    <form
      id="mc-embedded-subscribe-form"
      action="https://nanissa.us1.list-manage.com/subscribe/post?u=3f8caba634604170f8cb5ea46&amp;id=d1c9b138aa"
      method="post"
      name="mc-embedded-subscribe-form"
      class="validate p-4"
      target="_blank"
      novalidate
    >
      <div id="mc_embed_signup_scroll">
        <div class="indicates-required text-right mb-4">
          <span class="asterisk text-red-500">*</span> indicates required
        </div>
        <div class="mc-field-group grid grid-cols-1 gap-2 mb-4">
          <label for="mce-EMAIL">Email Address  <span class="asterisk text-red-500">*</span>
          </label>
          <input
            id="mce-EMAIL"
            type="email"
            value=""
            name="EMAIL"
            class="required email block w-full px-3 py-2 transition duration-100 ease-in-out border disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none text-black placeholder-gray-400 bg-white border-gray-300 focus:border-purple-500 rounded"
            required
          >
        </div>
        <div class="mc-field-group grid grid-cols-1 gap-2 mb-4">
          <label for="mce-FNAME">First Name </label>
          <input
            id="mce-FNAME"
            type="text"
            value=""
            name="FNAME"
            class="block w-full px-3 py-2 transition duration-100 ease-in-out border disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none text-black placeholder-gray-400 bg-white border-gray-300 focus:border-purple-500 rounded"
          >
        </div>
        <div class="mc-field-group grid grid-cols-1 gap-2 mb-4">
          <label for="mce-LNAME">Last Name </label>
          <input
            id="mce-LNAME"
            type="text"
            value=""
            name="LNAME"
            class="block w-full px-3 py-2 transition duration-100 ease-in-out border disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none text-black placeholder-gray-400 bg-white border-gray-300 focus:border-purple-500 rounded"
          >
        </div>
        <div class="mc-field-group grid grid-cols-1 gap-2 mb-4">
          <label for="mce-PHONE">Phone Number </label>
          <input
            id="mce-PHONE"
            type="text"
            name="PHONE"
            class="block w-full px-3 py-2 transition duration-100 ease-in-out border disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none text-black placeholder-gray-400 bg-white border-gray-300 focus:border-purple-500 rounded"
            value=""
          >
        </div>
        <div class="mc-field-group grid grid-cols-1 gap-2 mb-4 input-group">
          <strong>I am </strong>
          <ul>
            <li>
              <input
                id="mce-LEADTYPE-0"
                type="radio"
                value="A merchant wanting to sell online"
                name="LEADTYPE"
                class="mr-2 focus:outline-none"
              ><label for="mce-LEADTYPE-0">A merchant wanting to sell online</label>
            </li>
            <li>
              <input
                id="mce-LEADTYPE-1"
                type="radio"
                value="A frequent online shopper"
                name="LEADTYPE"
                class="mr-2 focus:outline-none"
              ><label for="mce-LEADTYPE-1">A frequent online shopper</label>
            </li>
            <li>
              <input
                id="mce-LEADTYPE-2"
                type="radio"
                value="An entrepreneur hoping to partner with Nanissa"
                name="LEADTYPE"
                class="mr-2 focus:outline-none"
              ><label for="mce-LEADTYPE-2">An entrepreneur hoping to partner with Nanissa</label>
            </li>
            <li>
              <input
                id="mce-LEADTYPE-3"
                type="radio"
                value="Looking for a job vacancy"
                name="LEADTYPE"
                class="mr-2 focus:outline-none"
              ><label for="mce-LEADTYPE-3">Looking for a job vacancy</label>
            </li>
            <li>
              <input
                id="mce-LEADTYPE-4"
                type="radio"
                value="Just interested in the project"
                name="LEADTYPE"
                class="mr-2 focus:outline-none"
              ><label for="mce-LEADTYPE-4">Just interested in the project</label>
            </li>
          </ul>
        </div>
        <div
          id="mce-responses"
          class="clear"
        >
          <div
            id="mce-error-response"
            class="response"
            style="display:none"
          />
          <div
            id="mce-success-response"
            class="response"
            style="display:none"
          />
        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div
          style="position: absolute; left: -5000px;"
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_3f8caba634604170f8cb5ea46_d1c9b138aa"
            tabindex="-1"
            value=""
          >
        </div>
        <div class="clear my-8">
          <input
            id="mc-embedded-subscribe"
            type="submit"
            value="Join to Our Mailing List"
            name="subscribe"
            class="button bg-gray-500 hover:bg-gray-600 rounded cursor-pointer px-4 py-2 text-white text-sm"
          >
        </div>
      </div>
    </form>
  </div>

  <!--End mc_embed_signup-->
</template>

<script>
export default {
  //
};
</script>

<style scoped>
/*#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }*/
</style>
