<template>
  <div class=" flex flex-col items-center justify-center p-4 hero">
    <div class="grid grid-cols-1 md:grid-cols-2 w-full container mx-auto">
      <div>
        <div class="flex space-x-4 items-center justify-center">
          <img
            class="w-12 h-12"
            src="../assets/icon.svg"
            alt="Nanissa"
          >
          <span class="font-glacial text-4xl font-black text-red-600">Nanissa</span>
        </div>
        <div class="max-w-lg mx-auto grid grid-cols-1 gap-6 mt-10">
          <div class="italic px-4 text-center mb-4">
            Would you like to start selling on nanissa.com? Or be among the first to start using Nanissa.com?
            Sign up to get the early bird advantage today.
          </div>
          <div class="text-center font-h font-bold text-xl">
            Be the First to know when we launch
          </div>
          <div class="text-center text-4xl text-purple-300 font-h font-bold">
            Coming Soon!
          </div>
        </div>
      </div>
      <div>
        <MailchimpForm />
      </div>
    </div>
    <div
      class="fixed bottom-5 right-0 bg-white p-2 rounded-l-lg shadow-lg"
    >
      <div class="flex space-x-2 items-center">
        <MailIcon
          class="w-6 cursor-pointer"
          @click="open = !open"
        />
        <a
          v-show="open"
          href="mailto:support@nanissa.com"
          class="hover:text-purple-500"
        >
          <span>support@nanissa.com</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import MailchimpForm from '@/components/MailchimpForm.vue';
import { MailIcon } from '@heroicons/vue/outline';

export default {
  components: { MailchimpForm, MailIcon },
  setup() {
    const open = ref(false);

    return { open };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
  background-image: url("../assets/background.svg");
}
</style>
